define("discourse/plugins/private-topics/discourse/templates/connectors/full-page-search-category/pvt-topic-search-list", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if result.topic.is_private}}
     <span class="tag-word discourse-tag box"> private </span>
  {{/if}}
  */
  {
    "id": "j2+lMHS6",
    "block": "[[[41,[30,0,[\"result\",\"topic\",\"is_private\"]],[[[1,\"   \"],[10,1],[14,0,\"tag-word discourse-tag box\"],[12],[1,\" private \"],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `result` property path was used in the `discourse/plugins/private-topics/discourse/templates/connectors/full-page-search-category/pvt-topic-search-list.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.result}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\"]]",
    "moduleName": "discourse/plugins/private-topics/discourse/templates/connectors/full-page-search-category/pvt-topic-search-list.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});