define("discourse/plugins/private-topics/discourse/pre-initializers/extend-for-force-pvt-cat-topics", ["exports", "discourse/models/category", "discourse-common/utils/decorators"], function (_exports, _category, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = {
    name: 'extend-for-force-pvt-cat-topics',
    before: 'inject-discourse-objects',
    initialize() {
      var _dec, _obj, _init;
      _category.default.reopen((_dec = (0, _decorators.default)('custom_fields.force_private_topics'), (_obj = {
        force_private_topics: {
          get(value) {
            return this.get("custom_fields.force_private_topics");
          },
          set(value) {
            value = value ? "true" : "false";
            this.set("custom_fields.force_private_topics", value);
            return value;
          }
        }
      }, (_applyDecoratedDescriptor(_obj, "force_private_topics", [_dec], (_init = Object.getOwnPropertyDescriptor(_obj, "force_private_topics"), _init = _init ? _init.value : undefined, {
        enumerable: true,
        configurable: true,
        writable: true,
        initializer: function () {
          return _init;
        }
      }), _obj)), _obj)));
    }
  };
});