define("discourse/plugins/private-topics/discourse/initializers/extend-for-pvt-topic", ["exports", "discourse/models/composer"], function (_exports, _composer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'extend-for-pvt-topic',
    initialize: function () {
      _composer.default.serializeOnCreate('is_private');
      _composer.default.serializeToTopic('is_private', 'topic.is_private');
    }
  };
});