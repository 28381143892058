define("discourse/plugins/private-topics/discourse/components/pvt-topic-chooser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.TextField.extend({
    classNameBindings: [':pvt-topic']
  });
});