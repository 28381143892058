define("discourse/plugins/private-topics/discourse/templates/connectors/category-custom-settings/force-private-topics", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if category.custom_fields }}
    <section class='field'>
      <label class="checkbox-label">
        {{input type="checkbox" checked=category.force_private_topics}}
        {{i18n 'category.force_private_topics'}}
      </label>
    </section>
  {{/if}}
  */
  {
    "id": "YSSEz1Xk",
    "block": "[[[41,[30,0,[\"category\",\"custom_fields\"]],[[[1,\"  \"],[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"checkbox-label\"],[12],[1,\"\\n      \"],[1,[28,[35,1],null,[[\"type\",\"checked\"],[\"checkbox\",[30,0,[\"category\",\"force_private_topics\"]]]]]],[1,\"\\n      \"],[1,[28,[35,2],[\"category.force_private_topics\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `category` property path was used in the `discourse/plugins/private-topics/discourse/templates/connectors/category-custom-settings/force-private-topics.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.category}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `category` property path was used in the `discourse/plugins/private-topics/discourse/templates/connectors/category-custom-settings/force-private-topics.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.category}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"input\",\"i18n\"]]",
    "moduleName": "discourse/plugins/private-topics/discourse/templates/connectors/category-custom-settings/force-private-topics.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});